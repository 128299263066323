<template>
  <ownership-tab
    :is-selected="isSelected"
    :ownership-stake="ownershipStake"
    :show-checkmark="!!showCheckmark && checkmark"
    :text="name"
  >
    <template #prefix>
      <router-link
        v-if="isPersonalInfo && formattedOwner.personal_profile_id"
        :to="profileLink"
        v-tooltip="
          $t('BUSINESS_PROFILES.INDIVIDUAL.VISIT_INDIVIDUAL_PROFILE', {
            individual: name
          })
        "
      >
        <icon-base
          :icon="IconVisit"
          :height="16"
          :width="16"
          class="text-blue-500"
        />
      </router-link>
      <div :class="[isSelected ? 'text-blue-600' : 'text-gray-400']">
        <icon-base
          :class="{
            'text-green-400': generated
          }"
          :icon="iconType"
          :height="isPersonalInfo ? '16' : '10'"
          :width="isPersonalInfo ? '16' : '10'"
          :current-icon="iconType"
          :role="`ownerCardIcon-${formattedOwner.id}`"
          @click="handleClick"
        />
      </div>
    </template>
  </ownership-tab>
</template>

<script setup lang="ts">
import { useAuth } from "@/hooks/auth";
import { useClipboardWithMessage } from "@/hooks/clipboard";
import type { IOwner } from "@/models/users";
import type { PropType } from "vue";
import { computed, ref } from "vue";
import type { OwnerWFL } from "@/models/workflows";
import { useStore } from "vuex";
import { useDeals } from "@/hooks/deals";
import IconVisit from "@/components/icons/IconVisit.vue";
import { ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO } from "@/router/routes";
import OwnershipTab from "@/views/deals/components/OwnershipTab.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  owner: {
    type: Object as PropType<IOwner | OwnerWFL>,
    required: true
  },
  isSelected: {
    type: Boolean,
    required: true
  },
  isPersonalInfo: {
    type: Boolean,
    default: false
  },
  checkmark: {
    type: Boolean,
    default: true
  },
  showOnwershipStake: {
    type: Boolean,
    default: true
  },
  index: {
    type: Number
  }
});

const isPersonaInfoOwner = (data: IOwner | OwnerWFL): data is OwnerWFL => {
  return props.isPersonalInfo;
};

const formattedOwner = computed(() => {
  if (!isPersonaInfoOwner(props.owner)) {
    return props.owner;
  }
  const personalInfo = props.owner;
  return {
    id: personalInfo.id,
    first_name: personalInfo?.personal_first_name || "",
    last_name: personalInfo?.personal_last_name || "",
    is_primary: personalInfo.personal_is_primary,
    ownership_percentage: personalInfo.personal_ownership_percentage,
    personal_profile_id: "",
    signed_docs: []
  };
});

const { isFunder } = useAuth();
const { canEditDealApplicationAndUnderwriting } = useDeals();
const { dispatch } = useStore();
const { t } = useI18n();
const isGenerating = ref(false);
const generated = ref(false);
const copyToClipboard = useClipboardWithMessage();

const showCheckmark = computed(() =>
  props.isPersonalInfo
    ? formattedOwner.value.is_primary
    : formattedOwner.value.signed_docs?.filter(
        (doc) => doc.signature_status === 2
      )?.length
);

const iconType = computed(() => {
  if (generated.value) {
    return "success-round-big";
  }
  if (props.isPersonalInfo) {
    return "link-small";
  }

  return "owner-small";
});

const name = computed(() =>
  formattedOwner.value.first_name || formattedOwner.value.last_name
    ? `${formattedOwner.value.first_name} ${formattedOwner.value.last_name}`
    : `${t("COMMON.INDIVIDUAL")} ${(props.index || 0) + 1}`
);

const profileLink = computed(() => {
  return formattedOwner.value.personal_profile_id
    ? {
        name: ROUTE_INDIVIDUAL_PROFILE_PERSONAL_INFO,
        params: {
          id: formattedOwner.value.personal_profile_id
        }
      }
    : "";
});

const ownershipStake = computed(() =>
  parseInt(formattedOwner.value.ownership_percentage || "0", 10)
);

const handleClick = async () => {
  if (
    !props.isPersonalInfo ||
    isGenerating.value ||
    isFunder ||
    !canEditDealApplicationAndUnderwriting.value
  ) {
    return;
  }
  isGenerating.value = true;

  const link = await dispatch("applications/generatePersonalInfoLink", {
    ownerId: props.owner.personal_information_id
  });
  copyToClipboard(link);

  generated.value = true;

  isGenerating.value = false;

  setTimeout(() => {
    generated.value = false;
  }, 3000);
};
</script>
